import React from "react"
import { Link } from "gatsby"

const Page404 = () => {
  return (
    <div className="h-screen flex justify-center items-center bg-main/10">
      <div className="">
        <p className="ss-h3 text-center mb-4">404</p>
        <h1 className="ss-h1 text-center mb-10">Page not found</h1>
        <p className="text-center">
          Go to{" "}
          <Link to="/" className="ss-link text-main font-bold">
            Home page
          </Link>
        </p>
      </div>
    </div>
  )
}

export default Page404
